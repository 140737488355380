<template>
    <div>
        <app-page-header></app-page-header>
        <v-container grid-list-xl fluid>
            <v-row>
                <v-col sm="12" md="12" lg="12" cols="12">
                    <v-card>
                        <v-card-title>
                            <span class="subtitle-1"><v-icon left>mdi-filter</v-icon> Uvoz plačil</span>
                            <v-spacer></v-spacer>
                            <v-btn icon @click="toggleCardContent('card1')">
                                <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-divider v-show="card1"></v-divider>
                        <v-card-text v-show="card1" id="form_card">
                            <!-- backend validation messages -->
                            <form-validation-messages :messages="apiValidationMessages">
                            </form-validation-messages>

                            <form-submit-messages :messages="apiMessages" :errorMessages="apiErrorMessages"></form-submit-messages>

                            <p class="body-2 font-weight-regular card--text"><v-icon class="p--icon" medium color="secondary" left>mdi-information</v-icon> Priložite datoteko za uvoz plačanih vlog. Podprti tipi datotek: .xml </p>
                            <v-form id="paymentst_import" ref="form" v-model="valid" lazy-validation>
                                <VueFileAgent
                                :uploadHeaders="{}"
                                :multiple="true"
                                :deletable="true"
                                :meta="true"
                                :accept="'.xml'"
                                :maxSize="'5MB'"
                                :maxFiles="1"
                                :helpText="'Dodaj datoteke...'"
                                :errorText="{
                                    type: 'Dovoljen tip datotek - xml, pdf do 3MB',
                                    size: 'Dodali ste datoteko, ki presega največjo dovoljeno velikost 3MB!',
                                }"
                                :thumbnailSize="120"
                                :theme="'list'"
                                v-model="files_data"
                                ></VueFileAgent>
                            </v-form>
                        </v-card-text>
                        <v-divider v-show="card1"></v-divider>
                        <span v-if="card1">
                            <v-card-text v-if="$vuetify.breakpoint.xsOnly">
                                <v-btn style="margin-bottom:10px;"   @click="submit()" color="secondary" dark  block outlined><v-icon left>mdi-application-import</v-icon>Uvozi&nbsp;</v-btn>
                                <v-btn style=""   @click="cancel()" color="error" dark  block outlined><v-icon left>mdi-close</v-icon>Prekliči&nbsp;</v-btn>
                            </v-card-text>
                            <v-card-text v-else>
                                <v-btn  @click="submit()" color="secondary" dark text><v-icon left>mdi-application-import</v-icon>Uvozi&nbsp;</v-btn>
                                <v-btn  @click="cancel()" color="error" dark text><v-icon left>mdi-close</v-icon>Prekliči&nbsp;</v-btn>
                            </v-card-text>
                        </span>
                    </v-card>
                </v-col>
            </v-row>
            <br>
            <imported-payments-data-table
            :settings="dataTableSettings"
            :queryParameters="queryParameters"
            ></imported-payments-data-table> 

            <br>

            <invoice-data-table
            :settings="invoiceDataTableSettings"
            :queryParameters="queryParameters"
            ></invoice-data-table> 
        </v-container>
        
        <data-table-toggle-columns
        :dataTableId="dataTableSettings.id"
        :columns="dataTableSettings.headers"
        :dataTableColumnsMenuId="dataTableSettings.eventBus.toggleColumnsId"

        ></data-table-toggle-columns>

        <data-table-toggle-columns
        :dataTableId="invoiceDataTableSettings.id"
        :columns="invoiceDataTableSettings.headers"
        :dataTableColumnsMenuId="invoiceDataTableSettings.eventBus.toggleColumnsId"

        ></data-table-toggle-columns>

    </div>
</template>

<script>
import { EventBus } from '@/main.js'
import { getCurrentDate, getCurrentTime, formatDate } from '@/helpers/utilities'
import { API_ENDPOINT } from '@/helpers/api'
import axios from 'axios'
const FormValidationMessages = () => import('@/components/FormValidationMessages.vue')
const FormSubmitMessages = () => import('@/components/FormSubmitMessages.vue')
const InvoiceDataTable = () => import('@/components/admin/InvoiceDataTable.vue')
const ImportedPaymentsDataTable = () => import('@/components/ImportedPaymentsDataTable.vue')
const DataTableToggleColumns = () => import('@/components/DataTableToggleColumns.vue')

export default {

    components: {
        FormValidationMessages,
        FormSubmitMessages,
        ImportedPaymentsDataTable,
        InvoiceDataTable,
        DataTableToggleColumns,
    },

    data: () => ({
        valid: true,
        card1: true,
        card2: true,
        loading: false,

        files_data: [],

        backendMessages: [],
        backendErrorMessages: [],
        backendValidationMessages:[],

        dataTableSettings: {
            id: 'dtPayments',
            title: 'Seznam uvoženih plačil',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                { text: "ID", value: "id", align: "left", visibility: true},
                { text: "Datoteka", value: "name", visibility: true},
                { text: "Status", value: "imported", visibility: true},
                { text: "Št. plačanih vlog", value: "paid_applications_count", visibility: true},
                { text: "Št. neveljavnih plačil", value: "unpaid_applications_count", visibility: true},
                { text: "UUID", value: "uuid", visibility: true, sortable: true},
                { text: "Čas uvoza", value: "created_at", visibility: true, sortable: true},
                { text: "Upravljanje", width:"15%", value: "action", align: "right", sortable: false, visibility: true}
            ],
            updateInterval: false,
            rowsPerPage: 10,
            endpoint: 'v1/services/payments',
            editItem: {
                route: '',
                routeParameterValue: ''
            },
            deleteItem: {
                vuexAction: '',
                replaceStrWithColumnValue: '',
                confirmationText : "",
                dialogConfirmSuccessText: '',
                dialogConfirmFailText: ''
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_payments',
                totalItems : {
                    event: ''
                },
                search: 'dt-payments-search',
                toggleColumnsId: 'toggle-payments-list-columns'
            }


        },

        invoiceDataTableSettings: {
            id: 'dtInvoices',
            title: 'Dnevnik računov',
            sortColumn: ['id'],
            sortDirection: ['desc'],
            progressHeight: 3,
            headers: [
                { text: "ID", value: "id", align: "left", visibility: true},
                { text: "Plačnik", value: "paid_application.payer_name", align: "left", visibility: true},
                { text: "Znesek (€)", value: "paid_application.amount", align: "left", visibility: true},
                { text: "Referenca", value: "paid_application.reference", align: "left", visibility: true},
                { text: "Datum plačila", value: "paid_application.bookg_dt_formatted", align: "left", visibility: true},
                { text: "Tip vloge", value: "application.digital", align: "left", visibility: true},
                { text: "Št. vloge", value: "application_id", align: "left", visibility: true},
                { text: "Upravljanje", width:"10%", value: "action", align: "right", sortable: false, visibility: true}
            ],
            updateInterval: false,
            rowsPerPage: 10,
            endpoint: 'v1/admin/data/invoices',
            editItem: {
                route: '',
                routeParameterValue: ''
            },
            deleteItem: {
                vuexAction: '',
                replaceStrWithColumnValue: '',
                confirmationText : "",
                dialogConfirmSuccessText: '',
                dialogConfirmFailText: ''
            },

            toolbar: {
                buttons: {
                    refresh: true,
                    export: false,
                    addNew: false,
                    addNewRoute: '',
                    toggleColumns: true
                }
            },

            eventBus: {
                refreshDataTable: 'refresh_dt_invoices',
                totalItems : {
                    event: ''
                },
                search: 'dt-invoices-search',
                toggleColumnsId: 'toggle-invoices-list-columns'
            }


        },
    }),

    watch: {

    },

    computed: {
        queryParameters() {
            return {}
        },

        apiMessages() {
            return this.backendMessages;
        },

        apiErrorMessages() {
            return this.backendErrorMessages 
        },

        apiValidationMessages() {
            return this.backendValidationMessages
        }
    },

    methods: {
        toggleCardContent(card) {
            if(card == 'card1') {
                this.card1 = !this.card1
            }

            if(card == 'card2') {
                this.card2 = !this.card2
            }
        },

        submit() {
            var vm = this
            vm.backendMessages = []
            vm.backendErrorMessages = []
            vm.backendValidationMessages = []

            vm.$store.commit('SET_LOADER_TEXT', 'Uvoz plačil je v teku...')
            vm.$store.commit('SET_LOADER', true)

            let payload = new FormData();

            let c = 1;
            vm.files_data.map(file => {
                payload.append('file_' + c, file.file)
                c++
            });

            vm.loading = true

            axios({
                method: 'POST',
                url: API_ENDPOINT + 'v1/services/payments/import',
                data: payload,
                headers: {
                    'Authorization': `Bearer ${vm.$store.state.user.access_token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                window.console.log(response)
                vm.clearForm()
                vm.backendMessages.push('Uvoz datoteke je končan.')
                    vm.$vuetify.goTo('#form_card', {
                    duration: 1000,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })

                setTimeout(() => {
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', false)
                    vm.$store.commit('SET_SNACKBAR_TEXT', "Vnos datoteke je končan.")
                    vm.$store.commit('SET_SNACKBAR_MODE', 'multi-line')
                    vm.$store.commit('SET_SNACKBAR_TIMEOUT', 7000)
                    vm.$store.commit('SET_SNACKBAR_DISPLAY', true)
                    EventBus.$emit(this.dataTableSettings.eventBus.refreshDataTable, 'pass-some-data');
                }, 700)

            })
            .catch(error => {
                window.console.log(error)
                window.console.log(error.response)

                if(error.response.status === 422) {
                    //window.console.log(error.response.data.errors)

                    for (const property in error.response.data.errors) {
                        //window.console.log(`${property}: ${error.response.data.errors[property]}`);
                        var propertyData = error.response.data.errors[property];
                        //window.console.log(propertyData);
                        propertyData.forEach(message => {
                            vm.backendValidationMessages.push(message);
                        })

                        vm.$vuetify.goTo('#form_card', {
                            duration: 1000,
                            offset: 0,
                            easing: 'easeInOutCubic'
                        })
                    }
                } else {
                    vm.backendErrorMessages.push('Ups... pri uvozu datoteke prišlo do napake. Poskusite ponovno.')
                    vm.$vuetify.goTo('#form_card', {
                        duration: 1000,
                        offset: 0,
                        easing: 'easeInOutCubic'
                    })
                }

            })
            .then(() => {
                setTimeout(() => {
                     vm.$store.commit('SET_LOADER', false)
                     vm.$store.commit('SET_LOADER_TEXT', '')
                }, 600)
                vm.loading = false
                //window.console.log("finally! :)");
            })

        },

        cancel() {
            this.files_data = []

            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []
        },

        clearForm() {
            this.files_data = []

            this.backendValidationMessages = []
            this.backendMessages = []
            this.backendErrorMessages = []

            setTimeout(() =>{
                this.$vuetify.goTo('#form_card', {
                    duration: 1000,
                    offset: 0,
                    easing: 'easeInOutCubic'
                })
            }, 200)
        }
    },

    created() {
        this.$store.dispatch('ROUTE_NAME', this.$route.name)
    },

    mounted() {

    },

    destroyed() {

    }

}

</script>

<style scoped>

</style>